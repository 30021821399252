<template>
    <header>
        <button class="menu-icon" :class="{ 'menu-icon__active': isActive }" @click="toggleClass">
            <span></span>
        </button>
        <div class="menu-container" :class="{ 'menu-container__active': isActive }">
           
        </div>
    </header>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const isActive = ref(false);
  
      const toggleClass = () => {
        isActive.value = !isActive.value;
      };
  
      return {
        isActive,
        toggleClass
      };
    }
  };
  </script>
  
  <style lang="scss">
  .menu{
    mask-position: relative;
    
    &-icon{
    cursor: pointer;
    padding:rem(10px 25px);
    background-color: transparent;
    border:none;
    span{
        background-color: var(--color-white);
        cursor: pointer;
        display:inline-block;
        position: relative;
        height: rem(2px);
        width: rem(30px);
        &::before,&::after{
            background-color: var(--color-white);
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            transition: all .2s ease-out;
            width: 100%;
        }
        &::before{
            top: rem(7.5px);
        }
        &::after{
            top: rem(-7.5px);
        }
    }
    &__active {
        span{
            background-color:transparent;
            &::before {
                top: 0;
                transform: rotate(-45deg)
            }
            &::after {
                top: 0;
                transform: rotate(45deg)
            }
        }
    }
    }
    &-container{
        position: absolute;
        // background-color:red;
        height: 100%;
        width: 100%;
        top:-100%;
        left: 0;
        transition: all .2s ease-out;
        z-index: -1;

        &__active {
            top: 0;

        }
    }
  }
  
  
  </style>
  