import { createApp } from 'vue';
import { createHead } from "@vueuse/head";
import App from './App.vue';
import VueScrollTo from 'vue-scrollto';

import './assets/scss/main.scss';

const app = createApp(App);
const head = createHead();

app.use(VueScrollTo);
app.use(head)
app.mount('#app');