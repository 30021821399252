<template>
    <picture>
        <source :srcset="webpSrc" type="image/webp" />
        <img :src="fallbackSrc" :alt="altText" :width="width" :height="height" />
    </picture>
</template>
  
<script>
export default {
    props: {
        webpSrc: {
            type: String,
            required: true,
        },
        fallbackSrc: {
            type: String,
            required: true,
        },
        altText: {
            type: String,
            default: '',
        },
        width: {
            type: [String, Number],
            default: null,
        },
        height: {
            type: [String, Number],
            default: null,
        },
    },
};
</script>
  