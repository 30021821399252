<template>
  <article>
    <SiteHeader/>
    <section class="site-section">
      <div class="site-section__container">
        <div class="site-section__column-right">
          <div class="intro__title">
            <h1>Solutii complete <span>pentru accesarea</span> de fonduri europene</h1>
            <div class="intro__icon">
              <svg width="14px" height="14px" viewBox="0 0 14 14">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1019.000000, -279.000000)" class="arrow" stroke-width="1.5">
                    <g id="arrow-up-right"
                      transform="translate(1026.000000, 286.000000) rotate(90.000000) translate(-1026.000000, -286.000000) translate(1020.000000, 280.000000)">
                      <polyline id="Path" points="2.76923077 0 12 0 12 9.23076923"></polyline>
                      <line x1="12" y1="0" x2="0" y2="12" id="Path"></line>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="site-section__column">
          <div class="intro__content">
            <p>Suntem dedicati succesului financiar al clientilor nostri, oferind solutii durabile, inovative,
              personalizate, plecand de la nevoile clientilor nostri.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section">
      <div class="site-section__container">
        <div class="site-section__column">
          <ResponsiveImage webpSrc="./images/section-image.webp" fallbackSrc="./images/section-image.png"
            altText="Solutii complete pentru accesarea de fonduri europene" />
        </div>
        <div class="site-section__column">
          <ResponsiveImage webpSrc="./images/section-image-1.webp" fallbackSrc="./images/section-image-1.png"
            altText="Solutii complete pentru accesarea de fonduri europene" />
        </div>
      </div>
    </section>

    <section class="site-section">
      <div class="site-section__container">
        <div class="site-section__column-large">
          <div class="about-section">
            <h2>Axipa Capital</h2>
              <p>Oferim solutii complete pentru accesarea de fonduri europene. Suntem dedicati succesului financiar al
            clientilor nostri, oferind solutii durabile, inovative, personalizate, plecand de la nevoile clientilor
            nostri. Fiecare proiect este diferit, dar toate au in comun implicarea noastra si accesarea cu succes de
            fonduri europene. Recrutam experti consultanti cu experienta, cu diverse backgrounduri de business, aducand
            diversitate de idei si know-how in relatia cu clientii, cu scopul de a creste calitatea serviciilor de
            consultanta fonduri Europene si de a maximiza sansele clientilor pentru accesarea fondurilor structurale.</p>
          <button v-scroll-to="'#section-target'">Contacteaza-ne</button>
        </div>
        </div>
      </div>
    </section>

    <section class="site-section" id="services-section">
      <div class="site-section__container">
        <div class="site-section__column-image">
            <ResponsiveImage webpSrc="./images/section-image-tall.webp" fallbackSrc="./images/section-image-tall.png"
            altText="Solutii complete pentru accesarea de fonduri europene" />
         
        </div>
        <div class="site-section__column">
          <div class="services">
            <h2>Servicii</h2>
            <p>Programele Operationale 2014-2020 ofera o gama larga de oportunitati in vederea dezvoltarii activitatii firmei dumneavoastra sau a dezvoltarii unor noi ramuri de activitate.Echipa noastra de specialisti va poate ajuta cu servicii de consultanta pentru realizarea documentatiei necesare privind accesarea fondurilor nerambursabile.De asemenea, echipa noastra sau reteaua noastra de parteneri va pot ajuta si cu servicii de implementare a proiectului, audit financiar sau oricare alte servicii financiare necesare implementarii acestor proiecte.</p>
            <h3>Programele pe care societatea noastra se axeaza sunt urmatoarele:</h3>
            <ul>
              <li>Programul Operational Regional</li>
              <li>Programul National de Dezvoltare Ruralal</li>
              <li>Programul Operational Competitivitate Umanal</li>
              <li>Horizont 2020 si COSME, programe gestionate de Comisia Europeana, cu depunere la Bruxelles.l</li>
            </ul>
          </div>
        </div>
        <div class="site-section__column-left">
          <div class="services">
          <h2>Proiecte guvernamentale</h2>
          <p>Aceste programe prezinta avantajul unui flux mai rapid de obtinere a fondurilor nerambursabile decat in cazul fondurilor europene si se adreseaza IMM-urilor care doresc accesarea a maxim 200.000 de euro.In functie de specificul activitatii dumneavoastra, specialistii nostri va pot recomanda programul cel mai potrivit.</p>
          </div>
        </div>
        <div class="site-section__column-left">
          <div class="services">
            <h2>Consultanta in sistemul financiar – bancar</h2>
            <p>Echipa noastra va poate oferi consultanta pentru accesarea finantarilor necesare desfasurarii activitatii dumneavoastra (flux de numerar, descoperit de cont, linie de credit) sau pentru implementarea unui proiect investitional (achizitie de terenuri si/sau cladiri, achizitii echipamente si utilaje, achizitii infrastructura IT, etc).</p>
          </div> 
        </div>
      </div>
    </section>
    <!-- <BackToTop /> -->
  </article>
</template>

<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
// import BackToTop from '@/components/BackToTop.vue';
import SiteHeader from './components/SiteHeader.vue'


export default {
  name: 'App',
    components: {
      // BackToTop,
      ResponsiveImage,
      SiteHeader
  },
  setup() {
    const siteData = reactive({
      title: `Axipa Capital - Solutii complete pentru accesarea de fonduri europene`,
      description: `Expertii nostri consultanti cu experienta in accesarea fondurilor europene ofera solutii personalizate si durabile pentru succesul financiar al clientilor nostri. Prin implicarea activa si know-how-ul diverselor backgrounduri de business, maximizam sansele clientilor de a accesa cu succes fondurile europene si structurale.`,
      keyowrds: `Fonduri Europene, Accesare fonduri europene,Consultanta fonduri europene,Experti consultanti fonduri europene,Accesare fonduri structurale,Solutii complete fonduri europene,Finantare europeana,Proiecte finantate de UE,Consultanta in fonduri europene,Accesare cu succes fonduri europene`
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keyowrds`,
          content: computed(() => siteData.keyowrds)
        }
        ],
        htmlAttrs: {
        lang: "ro",
      },
     
    })
  },
}
</script>


<style lang="scss">
.site-section {
  min-height: 70vh;
  margin: rem(75px 0);
  &__container {
    display: grid;
    max-width: calc(100% - 15px);
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    column-gap: rem(15px);
    row-gap: rem(15px);
  }

  &__column {
    grid-column: span 4;
    &-left {
      grid-column: span 4;
    }
    &-right {
      grid-column: span 4;
    }
    &-image{
      grid-column: span 4;
      grid-row: 1 / span 3;

    }
    &-large {
      grid-column: span 4;
    }
  }

  @include desktop {
    display: flex;
    align-items: center;
    &__container {
      max-width: calc(100% - 10%);
    }

    &__column {
      grid-column: span 2;

      &-left {
        grid-column: 3 / span 2;
      }
      &-right {
        grid-column: 3 / span 2;
      }
      &-image{
        grid-column: span 2;
        grid-row: 1 / span 3;

      }
      &-large {
        grid-column: span 4;
      }
    }
  }
}

.intro {

  &__title {
    text-align: right;
    margin: rem(75px 0 15px 0);
    
    h1 {
      font-family: var(--special-font);
      font-size: rem(25px);
      line-height: rem(35px);

      span {
        color: var(--color-gold);
      }
    }
  }
  &__icon{
    background-color:transparent;
    border:rem(solid 1px var(--color-white));
    border-radius: 50%;
    display: inline-block;
    margin:rem(50px 0 15px 0px);
    padding: rem(20px 25px);
    transition: background-color ease-in-out .4s;
    
    .arrow{   
      stroke:var(--color-white);
    }

    &:hover{
      background-color: var(--color-white);
      .arrow{   
        stroke:var(--color-gold);
      }
    }
    
  }
  &__content {
    max-width: rem(550px);
    padding: rem(25px 0px 50px 0);

    p {
      margin: rem(25px 0);
      font-size: rem(20px);
      font-weight: 400;
      line-height: rem(28px);
      // opacity: .6;

    }
  }
  @include desktop {
    &__title {
      margin: rem(150px 0 15px 0);
      
      h1 {
        font-size: rem(45px);
        line-height: rem(65px);
       }
    }
  }
}
.services{

  ul{
    margin: rem(15px 025px 0);
    padding: 0;
    list-style: none;
    li{
      padding:rem(2.5px 0)
    }
  }
  @include desktop {
    padding:rem(0 10%);
  }
}
.about-section {
  // padding:rem(0 20%);

    p{
      font-size: rem(20px);
      font-weight: 300;
      line-height: rem(26px);
    }

    button{
      background-color: transparent;
      border: rem(1px solid var(--color-gold));
      color:var(--color-white);
      cursor: pointer;
      display: block;
      margin: rem(15px auto);
      font-size:rem(16px);
      font-weight: 400;
      line-height: rem(22px);
      letter-spacing: rem(1.5px);

      padding: rem(15px 35px);
      text-transform: uppercase;
      transition:all ease-in-out .4s;
      &:hover{
        background-color: var(--color-gold);
        color: var(--color-white);
      }

    }
    @include desktop {
      padding:rem(0 20%);
    }
}

//typography

h2{
  font-family: var(--special-font);
  font-size:rem(22px);
  font-weight:400;
  line-height: rem(28px);
  margin:rem(10px 0);
  &::after {
    background-color: var(--color-gold);
    content:'';
    display: block;
    margin:rem(15px 0 25px 0);
    width:rem(100px);
    height: rem(2.5px);

  }
  @include desktop {
    font-size:rem(35px);
    font-weight:400;
    line-height: rem(45px);
    margin:rem(10px 0);
  }
}
h3 {
  font-size:rem(20px);
  font-weight:600;
  line-height: rem(28px);
  margin:rem(10px 0);
}
</style>
